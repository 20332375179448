// Landing Page

export const TITLE = "A Veteran and Minority";
export const TITLE_SPAN = "Owned and Operated Business";
export const TAG_LINE = "Where Technology Serves People";

// About us

export const ABOUT_US_TITLE = "About us";
export const ABOUT_US_DESC =
  "Intimidated ? don't think you’re qualified for a transition to an IT career ? Contact us at hr@codeaugurs.com  to schedule a 1 on 1 !!!  We provide excellent Training, certification and placement services that assists in your transition to a productive IT Career .";
//I want you for a career in IT “ > need to keep below unckle sam 

// Who are we 

export const WHAT_WE_DO_TITLE = "Who Are We ";
export const WHAT_WE_DO_DESC = "E Verified , Veteran, owned and Operated IT Staffing and training Company that counts highly experienced IT professionals among its directors . We are a leading pure play service provider of Systems and Storage in IT Infrastructure Services. We have nearly a decade of expertise in proprietary solutions , Effective Staffing Solutions and Managed IT training needs. ";

// Company's

export const COMPANY = 'Trusted by the world’s most ambitious teams.'

//What Set Us Apart

export const WHAT_SET_US_APART = "What sets 'Us' apart";
export const WHAT_SET_US_APART_DESC = "At Code Augurs, we utilise innovative models to join People, Processes and Services to successfully meet the expectations of our enterprise customers. Our managed services leverage accompanies of scale and expertise that add value and provide benefits over and above cost reduction such as higher SLA , reduced deployment times, and better alignment of IT functions with business goals.";

//Certifications
export const AFFILIATIONS = "AFFILIATIONS";

//services
export const SERVICES = "SERVICES OFFERED"
